<template>
  <div ref="divWidth">
    <v-container fluid>
      <v-row dense>
        <v-col @keydown.enter.exact.prevent="openGallery(i, card)" v-for="(card,i) in data" :key="card.id" :cols="colsBreakpoint" class="pa-6">
          <CardGalleryBlockCard :data="card"  @keydown.enter.exact.prevent="testFunc()" @click="openGallery(i, card)"/>
        </v-col>
      </v-row>
    </v-container>
    <div class="child-lightbox-container" 
 v-if="showGallery">
      <v-scale-transition origin="center center">
      <LightboxGallery
        :content="data"
        :startIndex="startIndex"
        @closed="closeGallery()"
        :showLightbox="showGallery"
      />
      </v-scale-transition>
    </div>
  </div>
</template>

<script>
import CardGalleryBlockCard from "./cardGalleryBlockCard";
// import axios from "axios"

export default {
  name: "CardGalleryBlock",
  props: ["data", "srcWidth"],
  data: () => ({
    showGallery: false,
    startIndex: 0,
    srcSize:0
  }),
  mounted() {
    console.log(this.srcWidth,this.$vuetify.breakpoint.name ,"cols breakpoint1")
    console.log(this.$parent.$el.clientWidth, this.$vuetify.breakpoint.thresholds , "test")
    this.srcSize = this.$refs.divWidth.clientWidth
     this.$nextTick(() => {
               window.addEventListener("resize", this.divWidth)

          });
    window.addEventListener("resize", this.divWidth)
  },
  beforeDestroy() {
    window.removeEventListener("resize")
  },
  components: { CardGalleryBlockCard, },
  computed:{
    colsBreakpoint(){
      if(this.$vuetify.breakpoint.name == 'xs' ||this.srcSize > 0 && this.srcSize < this.$vuetify.breakpoint.thresholds.xs ){
        console.log("hello1", this.srcSize)
        return 12
      }else if (this.$vuetify.breakpoint.name == 'sm' ||this.srcSize > 0  && this.srcSize < this.$vuetify.breakpoint.thresholds.sm){
                console.log("hello2")
        return 6
      }else if (this.$vuetify.breakpoint.name == 'md' ||this.srcSize > 0  && this.srcSize < this.$vuetify.breakpoint.thresholds.md ){
                console.log("hello3")
        return 4
      }else if (this.$vuetify.breakpoint.name == 'lg' || this.srcSize > 0  && this.srcSize < this.$vuetify.breakpoint.thresholds.lg){
                console.log("hello4")
        return 3
      }else {
      console.log ("hello5"); 
      return 3
      }
    }
  },
  methods: {
    divWidth(){
    this.srcSize = this.$parent.$el.clientWidth
    console.log(this.srcSize,this.$parent.$el.clientWidth , this.$vuetify.breakpoint.name,"fr divWidth")
    },
    openGallery(index) {
      console.log("gallery open")
      this.startIndex = index
      this.showGallery = true
    },
    closeGallery() {
      this.showGallery = false
    },
    downloadDocument: function (url, label) {
      console.log(label)
      window.open(url)
      // axios.get(url, { responseType: 'blob' })
      // .then(response => {
      //   const blob = new Blob([response.data], { type: 'application/pdf' })
      //   const link = document.createElement('a')
      //   link.href = URL.createObjectURL(blob)
      //   link.download = label
      //   link.click()
      //   URL.revokeObjectURL(link.href)
      // }).catch(console.error)
    },
  },
  watch:{
    colsBreakpoint(newVal){
      console.log(newVal, "cols breakpoint")
    }
  }
};
</script>

<style scoped>
.child-lightbox-container {
  position: absolute;
  top: 50px;
  bottom: 50px;
  left: 50px;
  right: 50px;
  z-index: 100;
  /* pointer-events: none; */
}
.gallery {
  pointer-events: all;
}
.fitters{
  max-width: fit-content;
}
</style>