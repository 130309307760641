import { render, staticRenderFns } from "./cardGalleryBlock.vue?vue&type=template&id=070a5bc4&scoped=true&"
import script from "./cardGalleryBlock.vue?vue&type=script&lang=js&"
export * from "./cardGalleryBlock.vue?vue&type=script&lang=js&"
import style0 from "./cardGalleryBlock.vue?vue&type=style&index=0&id=070a5bc4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070a5bc4",
  null
  
)

export default component.exports